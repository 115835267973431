<template>
<div>
    <form @submit.prevent="" v-on:submit="resetPassword()">
        <div>
            <div class="newLine">
            <label>New password</label>
            <input type="password" id="password" placeholder="password" required/>
            </div>
            <div class="newLine">
            <label>Confirm your new password</label>
            <input type="password" id="confirm_password" placeholder="confirm password" required/>
            </div>
        </div>
        <button type="submit">Reset Password</button>
    </form>
</div>
</template>

<script>
const axios = require('axios');
export default {
    data: () => ({
        email: ''
    }),
    methods: {
        async resetPassword() {
            if (document.getElementById("password").value.length < 7) {
                alert("Password must be a minimum of 7 characters")
            return
            }
            if (document.getElementById("password").value == document.getElementById("confirm_password").value) {
                var headers = {
                    token: this.$route.query.token
                }

                var data = {
                    password: document.getElementById("password").value,
                    email: this.email
                }
                await axios.post('https://api.fkv-vss.com/api/ResetPassword', data, {headers:headers}).then(function (response) {
                    
                alert(response.data.message)
                if (response.data.message === "Your password has been reset successfully !") {
                    window.location.replace('https://fkv-vss.com');
                }
                })
            }
            else {
                alert("Passwords don't appear to match")
            }
        }
    }
    
}
</script>

<style scoped>
label {
  display: block;
  text-align: left;
  margin-left: 18%;
}
.newLine{
  width: 50%;
  margin-left: 25%;
}
* {
    width: 100%;
    margin-left: 0%;
}
label {
    text-align: left;
    margin-left: 0%;
}
button{
    width: 163px;
}
@media only screen and (max-width: 820px) {
    .newLine {
        width: 100%;
        margin-left: 0%;
    }
}
@media only screen and (max-width: 474px) {
  * {
    width: 100%;
    margin-left: 0%;
  }
  label {
    text-align: left;
    margin-left: 0%;
  }
  button{
    width: 100%;
  }  
}
</style>